/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'
import Header from './header'
import Footer from './footer'
import ReactTooltip from 'react-tooltip'
import { Helmet } from 'react-helmet'
import getHrefLangsForHelmet from '../../utils/getHreflangsForHelmet'

const Layout = ({ localizedPath, children }) => {
  const hreflangs = getHrefLangsForHelmet(localizedPath ?? [])

  return (
    <>
      <Helmet>
        {hreflangs?.map((hreflang) => {
          return <link rel="alternate" hrefLang={hreflang.isoCode} href={hreflang.path} />
        })}
        <link rel="apple-touch-icon" sizes="180x180" href={process.env.GATSBY_WEBSITE_URL + `/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={process.env.GATSBY_WEBSITE_URL + `/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={process.env.GATSBY_WEBSITE_URL + `/favicon-16x16.png`} />
        <link rel="manifest" href={process.env.GATSBY_WEBSITE_URL + `/site.webmanifest`} />
        <link rel="mask-icon" href={process.env.GATSBY_WEBSITE_URL + `/safari-pinned-tab.svg`} color="#3883c4" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="google-site-verification" content="lx2FN2ZnFxhWEmNjkZoPrLtp30VKniWmW539LGvTKF0" />
        <body className={process.env.LOCALE !== `cs` ? `ENUS` : `CSCZ`} />
      </Helmet>
      <StickyContainer>
        <Header localizedPaths={localizedPath} />
        <main>{children}</main>
        <Footer></Footer>
      </StickyContainer>
      <ReactTooltip clickable={true} html={true} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "pux s. r. o.",
            "url": "http://www.puxdesign.cz",
            "logo": "https://www.puxdesign.cz/logo.svg",
            "email": "info@puxdesign.cz",
            "telephone": "+420 777 789 190",
            "sameAs": [
              "https://www.facebook.com/PUXdesign/", 
              "https://www.instagram.com/puxdesign_cz/", 
              "https://www.linkedin.com/company/puxdesign-cz"
            ]
          }`,
        }}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
