import { graphql, useStaticQuery } from 'gatsby'

import { getLocalizedData } from '../../utils/localeURL'
const localizedConfigData = getLocalizedData(
  process.env.LOCALE,
  process.env.PROJECT
)

export const useFooterData = () => {
  const {
    orchard: { menu },
  } = useStaticQuery(
    graphql`
      {
        orchard {
          menu(where: {alias: {alias_ends_with: "Footer"}}) {
            alias {
              alias
            }
            menuItemsList {
              menuItems {
                ... on Orchard_LinkMenuItem {
                  contentType
                  displayText
                  linkMenuItem {
                    name
                    url
                  }
                }
                ... on Orchard_ContentMenuItem {
                  contentType
                  displayText
                  render
                }
              }
            }
          }
        }
      }
    `
  )

  var languageMenu = menu.filter(
    (m) => m.alias.alias.indexOf(localizedConfigData.isoCode) === 0
  )

  return languageMenu && languageMenu.length
    ? languageMenu[0].menuItemsList
    : menu[0].menuItemsList
}