import { graphql, useStaticQuery } from 'gatsby'

import { getLocalizedData } from '../../utils/localeURL'
const localizedConfigData = getLocalizedData(
  process.env.LOCALE!!,
  process.env.PROJECT!!
)

interface SocialsQuery {
  orchard: {
    puxSocials: ISocials[]
  }
}

export interface ISocials {
  localization: {
    culture: "cs-CZ" | "en-US"
  },
  socialsList: {
    contentItems: ISocialItem[]
  }
}

interface ISocialItem {
  displayText: string,
  socialsType: string,
  socialsUrl: string
}


export const useSocialsData = () => {
  const {
    orchard: { puxSocials },
  } = useStaticQuery<SocialsQuery>(
    graphql`
      {
        orchard { 
          puxSocials {
            localization {
              culture
            }
            socialsList {
              contentItems {
                ... on Orchard_PuxSocialItem {
                  displayText
                  socialsType
                  socialsUrl
                }
              }
            }
          }
        }
      }
    `
  )

  var socialsList = puxSocials?.find(
    (s) => s.localization.culture.includes(localizedConfigData.isoCode)
  )

  return socialsList
}