export const localeConfig: configType = {
  PuxDesignGatsby: {
    cs: {
      locale: `cs`,
      urlPrefix: `cz`,
      isoCode: `cs-CZ`,
      domains: {
        development: `http://localhost:8000`,
        prod: `http://puxdesign.cz`,
        test: `http://puxdesign.puxdesign.net`,
        stage: `https://puxdesign.temp-sol.puxdesign.cz`,
      },
    },
    en: {
      locale: `en`,
      urlPrefix: `en`,
      isoCode: `en-US`,
      domains: {
        development: `http://localhost:8000`,
        prod: `http://puxdesign.cz`,
        test: `http://puxdesign.puxdesign.net`,
        stage: `https://puxdesign.temp-sol.puxdesign.cz`,
      },
    },
  },
}

export interface configType {
  [site: string]: siteConfigType
}

export interface siteConfigType {
  [lang: string]: langConfig
}

export interface langConfig {
  locale: string
  urlPrefix: string
  isoCode: string
  domains: langDomain
}

export interface langDomain {
  [domain: string]: string
}

interface PTConfig {
  codeName: string
  componentName: string
  zones: string[]
}

interface PTConfigs {
  [key: string]: PTConfig
}

export const ptConfigs: PTConfigs = {
  PUXDesignPage: {
    codeName: `pUXDesignPage`,
    componentName: `PreviewDefault`,
    zones: [`widgetZone`],
  },
  Blog: {
    codeName: `blog`,
    componentName: `PreviewBlogDetail`,
    zones: [`mainWidgetZone`],
  },
  PuxDesignBlogCategory: {
    codeName: `puxDesignBlogCategory`,
    componentName: `PreviewBlog`,
    zones: [],
  },
  PuxDesignReference: {
    codeName: `puxDesignReference`,
    componentName: `PreviewReferenceDetail`,
    zones: [`referenceWidgets`],
  },
  PuxDesignContactPage: {
    codeName: `puxDesignContactPage`,
    componentName: `PreviewContact`,
    zones: [`widgetsPuxDesignContactPage`],
  },
  PuxDesignCareer: {
    codeName: `puxDesignCareer`,
    componentName: `PreviewCareer`,
    zones: [`careerWidgetsTop`, `careerWidgetsBottom`],
  },
  PuxDesignHowDoWeWork: {
    codeName: `puxDesignHowDoWeWork`,
    componentName: `PreviewHowDoWeWork`,
    zones: [`puxDesignHowDoWeWorkWidgets`],
  },
  HomePage: {
    codeName: `homePage`,
    componentName: `PreviewHomepage`,
    zones: [`flow`],
  },
  PuxLandingPage: {
    codeName: `puxLandingPage`,
    componentName: `PreviewLandingPage`,
    zones: [`landingWidgets`],
  },
}
