import { domainCultures, getDomainsWithCultures, getLocalizedUrl } from './localeURL'

interface hreflangType {
  path: string
  isoCode: string
}

const activeEnv = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || `development`).trim()

const activeProject = (process.env.PROJECT || `EasyRedmineGatsby`).trim()

export const getDomainByCulture = (culture: string): string => {
  const domainsWithCultures: [domainCultures] = getDomainsWithCultures(activeEnv, activeProject) ?? []

  const domainByCulture: domainCultures[] = domainsWithCultures.filter((domain) => domain.isoCodes.includes(culture))

  return domainByCulture[0]?.domain ?? ``
}

const getHrefLangsForHelmet = (localizedPath: any[]): hreflangType[] => {
  const clearedLocalizedPath = [...new Set(localizedPath)]
  const hreflangs: hreflangType[] = []

  clearedLocalizedPath?.map((localizedPath) => {
    if (localizedPath.published) {
      const culture = localizedPath.localization.culture
      const path = getLocalizedUrl(localizedPath.path)
      const domain = getDomainByCulture(culture)
      let absolutePath = path.startsWith(`http`) ? path : domain + path

      if (absolutePath.match(/(.com|.cz|.hu)\/[a-z][a-z]\/$/)) {
        absolutePath = absolutePath.slice(0, -1)
      }

      hreflangs.push({
        path: absolutePath,
        isoCode: culture.toLowerCase(),
      })
    }

    return
  })

  return hreflangs
}

export default getHrefLangsForHelmet
