import React from "react"
import { useSocialsData } from "./_use-socials-data"

const iconInstagram: JSX.Element = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <radialGradient id="radial-gradient" cx="10.04" cy="34.06" r="35.57" gradientTransform="scale(.75)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#fae100" />
        <stop offset=".05" stop-color="#fadc04" />
        <stop offset=".12" stop-color="#fbce0e" />
        <stop offset=".18" stop-color="#fcb720" />
        <stop offset=".25" stop-color="#fe9838" />
        <stop offset=".31" stop-color="#ff7950" />
        <stop offset=".49" stop-color="#ff1c74" />
        <stop offset="1" stop-color="#6c1cd1" />
      </radialGradient>
      <style dangerouslySetInnerHTML={{ __html: ".insta{fill:#fff}" }} />
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle cx="15" cy="15" r="15" style={{ fill: "url(#radial-gradient)" }} />
        <path className="insta" d="M15 10.39A4.65 4.65 0 1 0 19.65 15 4.66 4.66 0 0 0 15 10.39Zm0 7.33A2.68 2.68 0 1 1 17.68 15 2.68 2.68 0 0 1 15 17.72Z" />
        <path className="insta" d="M19.47 6h-8.94A4.55 4.55 0 0 0 6 10.57v8.94a4.55 4.55 0 0 0 4.55 4.55h8.94A4.55 4.55 0 0 0 24 19.51v-8.94A4.55 4.55 0 0 0 19.47 6Zm2.59 13.49a2.59 2.59 0 0 1-2.59 2.59h-8.94a2.59 2.59 0 0 1-2.59-2.59v-8.92A2.59 2.59 0 0 1 10.53 8h8.94a2.59 2.59 0 0 1 2.59 2.59Z" />
        <path className="insta" d="M19.8 9.15a1.1 1.1 0 1 0 1.1 1.1 1.1 1.1 0 0 0-1.1-1.1Z" />
      </g>
    </g>
  </svg>
)

const iconFacebook: JSX.Element = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: ".faceb{fill:#23599a;}.facew{fill:#fff;}"
        }}
      />
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="faceb" cx={15} cy={15} r={15} />
        <path
          className="facew"
          d="M18.77,15.59H16.09v9.8H12v-9.8H10.11V12.14H12V9.91a3.8,3.8,0,0,1,4.09-4.09h3V9.18H17a.83.83,0,0,0-.86.94v2h3Z"
        />
      </g>
    </g>
  </svg>
)

const iconLinkedin: JSX.Element = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: ".linke{fill:#007bbc;}.linkew{fill:#fff;}"
        }}
      />
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="linke" cx={15} cy={15} r={15} />
        <path
          className="linkew"
          d="M24,16.21v6.18H20.38V16.62c0-1.45-.52-2.44-1.82-2.44a2,2,0,0,0-1.84,1.31,2.57,2.57,0,0,0-.12.88v6H13s.05-9.77,0-10.78H16.6v1.53l0,0h0v0a3.56,3.56,0,0,1,3.23-1.79c2.36,0,4.13,1.55,4.13,4.86ZM9.27,6.41a1.87,1.87,0,1,0,0,3.73h0a1.87,1.87,0,1,0,0-3.73Zm-1.82,16H11V11.61H7.45Z"
        />
      </g>
    </g>
  </svg>
)

const Socials = () => {
  const socialsData = useSocialsData()

  if (socialsData) {
    return (
      <div className="socials">
        {
          socialsData.socialsList.contentItems.map((item, index) => {
            let icon: JSX.Element | null;

            switch (item.socialsType) {
              case "facebook":
                icon = iconFacebook
                break;
              case "linkedin":
                icon = iconLinkedin
                break;
              case "instagram":
                icon = iconInstagram
                break;
              default:
                icon = null
                break;
            }

            return (
              <a
                key={index}
                target="_blank"
                className={`social-item social-item-${item.socialsType}`}
                href={item.socialsUrl}
              >
                {icon}
              </a>
            )
          })
        }
      </div>
    )
  }

  return null
}

export default Socials
