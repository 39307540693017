import React, { FunctionComponent } from "react";
import {
  getIsoCodesWithPrefixPublic,
  getLocalizedUrl,
  isoCodePrefix,
} from "../../utils/localeURL";


import FlagCs from "./flags/cs.svg";
import FlagEn from "./flags/en.svg";

const rootClass = `pux-language-selector`;

const getFlagUrl = (lang: string) => {
  switch (lang) {
    case `en`:
      return FlagEn;
    default:
      return FlagCs;
  }
};

interface PuxLanguageSelectorType {
  localizedPaths?: localizedPathType[];
}

export interface localizedPathType {
  path: string;
  published: boolean;
  localization: {
    culture: string;
  };
}

const PuxLanguageSelector: FunctionComponent<PuxLanguageSelectorType> = (
  props
) => {
  const locales = getIsoCodesWithPrefixPublic(true);
  const activeLang: string = process.env.LOCALE ? process.env.LOCALE : `cs`;

  const getLocalizedPath = (locale: isoCodePrefix): string => {
    const localizedPaths = props.localizedPaths;
    const defaultPath = `${locale.domain}/${locale.prefix}`;

    if (!localizedPaths) {
      return defaultPath;
    }

    const localizedPath = localizedPaths.filter(
      (localizedPath) => localizedPath.localization.culture === locale.isoCode
    );

    if (!localizedPath.length) {
      return defaultPath;
    }

    const localizedUrl = getLocalizedUrl(localizedPath[0].path);

    if (
      localizedUrl.startsWith(`http://`) ||
      localizedUrl.startsWith(`https://`)
    ) {
      return localizedUrl;
    }

    return locale.domain + localizedUrl;
  };

  return (
    <div className="lng-selector">
      {locales.reverse().map((locale, idx) => (
          <a
            key={idx}
            className={activeLang === locale.locale ? `is-active` : ``}
            href={getLocalizedPath(locale)}
          >
            <img
              className={`${rootClass}-flag`}
              src={getFlagUrl(locale.locale)}
              alt={locale.locale}
            />
          </a>
      ))}
    </div>
  );
};

export default PuxLanguageSelector;
