import React from "react"

type AwardType = {
    image: string
    textTop: string,
    textBottom: string,
    url: string
}

const Award = (props: AwardType) => {
    return (
        <a href={props.url} className="award">
          <div className="award-image">
            <img width="66" height="32" src={props.image} />
          </div>
          <div className="award-text">
            <div className="award-text-top">{props.textTop}</div>
            <div className="award-text-bottom">{props.textBottom}</div>
          </div>
        </a>
    )
}

export default Award
