import { Link } from "gatsby"
import React from "react"
import { useFooterData } from './_use-footer-data.js'
import { getLocalizedUrl } from "../../utils/localeURL"
import Socials from "./socials"
import Award from "./award"

let { LOCALE } = process.env;
LOCALE = LOCALE ? LOCALE : 'cs';

interface ContentMenuItem {
  contentType: 'ContentMenuItem'
  displayText: string
  render: string
}

interface LinkMenuItem {
  contentType: 'LinkMenuItem'
  linkMenuItem: {
    name: string
    url: string
  }
}

type MenuItem = ContentMenuItem | LinkMenuItem

interface FooterData {
  menuItems: MenuItem[]
}

export const renderFooterItem = (item: MenuItem, delay: number) => {
  switch (item.contentType) {
    case `ContentMenuItem`:
      const itemData = JSON.parse(item.render)
      return (
        <li style={{ transitionDelay: `${delay}s` }}>
          <Link to={getLocalizedUrl(itemData.url)}>{itemData.name}</Link>
        </li>
      )
    case `LinkMenuItem`:
      return (
        <li style={{ transitionDelay: `${delay}s` }}>
          <a target="_blank" href={item.linkMenuItem.url}>{item.linkMenuItem.name}</a>
        </li>
      )
  }
}

const Footer = () => {
  const footerData: FooterData = useFooterData()

  return (
    <footer>
      <div className='pux-container'>
        <div className='content'>
          <nav>
            <ul>
              {
                footerData.menuItems.map(item => renderFooterItem(item))
              }
            </ul>
          </nav>
          <Socials />
          {process.env.LOCALE === `en` &&
            <div className='copyright'>© {new Date().getFullYear()} BiQ pux a.s.</div>
          }
        </div>
      </div>
      {process.env.LOCALE === `cs` &&
        <div className='pux-container'>
          <div className='content'>
            <div className="awards">
              <Award textTop={"1. místo"} textBottom={"Autentická firemní komunikace 2023"} image={"/icons/WEBTOP100.svg"} url={"https://www.puxdesign.cz/cz/brnoregion"} />
              <Award textTop={"2. místo"} textBottom={"Firemní web 2023"} image={"/icons/WEBTOP100.svg"} url={"https://www.puxdesign.cz/cz/prezentacni-web-niveko"} />
              <Award textTop={"Site of the Year 2023"} textBottom={"Honorable mention"} image={"/icons/kentico.svg"} url={"https://www.puxdesign.cz/cz/brnoregion"} />
            </div>
            <div className='copyright'>© {new Date().getFullYear()} BiQ pux a.s.</div>
          </div>
        </div>
      }
    </footer>
  )
}

export default Footer
